import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Actions } from "../../redux-flow/actions/_index";
import "./signup.css";
import FormLayout from "../../reuse/form";
import Static from "../../reuse/Static";
import Joi from "joi-browser";
import { Button } from "@material-ui/core";
import CustomTextField from "../../reuse/customInput";
import SignUpForm from "../../reuse/signUpForm";
import { FooterLogoIcon } from "../../reuse/svgIcons";
// import { url } from "../../App";
export const url = "/customer/";

class Signup extends FormLayout {
  state = {
    page: 1,
    data: {
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      password: "",
    },
    data2: {
      companyName: "",
      companyEmail: "",
      companyContact: "",
      companyPhone: "",
      companyAddress: "",
      RC_no: "",
      websiteUrl: "",
    },
    checkBoxValue: false,
    checkBoxError: false,
    moveBar1: false,
    moveBar2: false,
    errors: {},
    styles: {},
    isTermsClicked: false,
  };

  schema = {
    email: Joi.string()
      .regex(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "email"
      )
      .required()
      .label("Email Address"),
    firstName: Joi.string().required().label("First Name"),
    phone: Joi.string()
      .regex(
        /^((\d{8,9})|[0]?\d([7](?=0)|[8](?=0|1)|[9](?=0))\d{9}(?!\d))$/,
        "phone number"
      )
      .required()
      .label("Phone Number"),
    password: Joi.string()
      .min(7)
      .max(20)
      .alphanum()
      .required()
      .label("Password"),
    lastName: Joi.string().required().label("Last Name"),
  };

  schema2 = {
    companyName: Joi.string().required().label("Company Name"),
    companyEmail: Joi.string()
      .required()
      .regex(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "email"
      )
      .label("Company Email"),
    companyContact: Joi.any().optional(),
    companyPhone: Joi.string()
      .regex(
        /^((\d{8,9})|[0]?\d([7](?=0)|[8](?=0|1)|[9](?=0))\d{9}(?!\d))$/,
        "phone number"
      )
      .required()
      .label("Company Phone"),
    companyAddress: Joi.string().required().label("Company Address"),
    RC_no: Joi.string().alphanum().required().label("RC Number"),
    websiteUrl: Joi.any().optional(),
  };

  Continue = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({
      errors: errors || {},
    });
    if (errors) return;
    return this.setState({ page: 2, moveBar1: true });
  };

  checkBox = ({ target: { checked } }) => {
    // if (this.state.isTermsClicked) {
    this.setState({
      checkBoxValue: checked,
      checkBoxError: checked ? false : true,
    });
    // }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      data: { email, firstName, lastName, phone, password },
      data2: {
        companyName,
        companyEmail,
        companyContact,
        companyPhone,
        companyAddress,
        websiteUrl,
        RC_no,
      },
      checkBoxValue,
    } = this.state;

    const customerInfo = {
      companyName,
      emailAddress: companyEmail,
      contactName: companyContact,
      phoneNo: companyPhone,
      address: companyAddress,
      websiteUrl,
      regNo: RC_no,
    };

    // const url = "/customer/"

    const customerAdmin = {
      emailAddress: email,
      firstName,
      lastName,
      phoneNo: phone,
      password,
      role: 1,
    };

    const { dispatch } = this.props;
    const errors = this.validate(true);
    this.setState({
      errors: errors || {},
      checkBoxError: !checkBoxValue ? true : false,
    });
    if (errors || !checkBoxValue) return;
    dispatch(Actions.uiStart());
    this.setState({ moveBar2: true });
    dispatch(
      Actions.signupUser({
        customerAdmin,
        customerInfo,
      })
    );
  };

  goBack = () => this.setState({ page: 1 });

  render() {
    const {
      data: { firstName },
      page,
      checkBoxValue,
      checkBoxError,
      moveBar1,
      moveBar2,
      errors,
      // isTermsClicked
    } = this.state;
    const { loading } = this.props;
    return (
      <div className="isw-login">
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-lg-8">
              <div
                className="isw-login--middle"
                style={{
                  overflowY: "auto",
                  displaY: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="isw-login--middle-form">
                  {page === 1 ? (
                    <form
                      onSubmit={this.Continue}
                      className="login-form w-100 row form"
                      style={{
                        maxWidth: `${35}rem`,

                        margin: `${0} auto`,
                      }}
                    >
                      <div className="col-12">
                        <header>
                          <h1 className="text-primary">Get Started!</h1>
                          <div className="sub-text">
                            Sign up by entering the information below
                          </div>
                        </header>
                      </div>
                      {/* <div className="col-12">
                        <div className="pb-3">
                          <div
                            className="progress"
                            style={{
                              height: `${0.3}rem`,
                              marginBottom: `${1}rem`,
                            }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{
                                width:
                                  moveBar1 && Object.keys(errors).length === 0
                                    ? "50%"
                                    : "0%",
                              }}
                              aria-valuenow={
                                moveBar1 && Object.keys(errors).length === 0
                                  ? "50"
                                  : "0"
                              }
                              aria-valuemin="0"
                              aria-valuemax="100"
                            />
                          </div>
                          <h3>Enter Personal information details</h3>
                        </div>
                      </div> */}
                      {/* <CustomTextField /> */}
                      {/* <div className="form-field mb-3 col-6">
                        {this.renderInput("firstName", "First Name")}
                      </div>
                      <div className="form-field mb-3 col-6">
                        {this.renderInput("lastName", "Last Name")}
                      </div>
                      <div className="form-field mb-3 col-6">
                        {this.renderInput("email", "Email Address")}
                      </div>
                      <div className="form-field mb-3 col-6">
                        {this.renderInput("Vendor", "Accountype")}
                      </div>
                      <div className="form-field mb-3 col-6">
                        {this.renderInput("password", "Password")}
                      </div>
                      <div className="form-field mb-3 col-6">
                        {this.renderInput("phone", "Re-type Password")}
                      </div> */}
                      <SignUpForm />
                    </form>
                  ) : (
                    <form
                      className="login-form w-100 row"
                      style={{
                        maxWidth: `${30}rem`,

                        margin: `${0} auto`,
                      }}
                    >
                      <div className="col-12">
                        <header>
                          <h1 className="text-primary">
                            Hi <span>{firstName}</span>!
                          </h1>
                          <p>Let set up your company information</p>
                        </header>
                      </div>

                      <div className="col-12">
                        <div className="pb-3">
                          <div
                            className="progress"
                            style={{
                              height: `${0.3}rem`,
                              marginBottom: `${1}rem`,
                            }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{
                                width:
                                  moveBar2 &&
                                  Object.keys(errors).length === 0 &&
                                  !checkBoxError
                                    ? "100%"
                                    : "50%",
                              }}
                              aria-valuenow={
                                moveBar2 &&
                                Object.keys(errors).length === 0 &&
                                !checkBoxError
                                  ? "100"
                                  : "50%"
                              }
                              aria-valuemin="0"
                              aria-valuemax="100"
                            />
                          </div>

                          <h3>Enter Corporate information details</h3>
                        </div>
                      </div>

                      <div className="form-field mb-3 col-12">
                        {this.renderInput2("companyName", "Company Name")}
                      </div>

                      <div className="form-field mb-4 col-6">
                        {this.renderInput2("RC_no", "RC Number")}
                        <div className="form-field__control" />
                      </div>
                      <div className="form-field mb-4 col-6">
                        {this.renderInput2("companyPhone", "Company Phone")}
                      </div>

                      <div className="form-field mb-4 col-12">
                        {this.renderInput2(
                          "companyContact",
                          "Company Contact",
                          false
                        )}
                      </div>
                      <div className="form-field mb-4 col-12">
                        {this.renderInput2("companyEmail", "Company Email")}
                      </div>
                      <div className="form-field mb-4 col-12">
                        {this.renderInput2("companyAddress", "Company Address")}
                      </div>
                      <div className="form-field mb-4 col-12">
                        {this.renderInput2(
                          "websiteUrl",
                          "Company Website",
                          false
                        )}
                      </div>

                      <div className="col-12 mb-4">
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              onClick={this.checkBox}
                              value={checkBoxValue}
                              onChange={this.checkBox}
                              checked={checkBoxValue ? true : false}
                            />
                            <i className="helper" />
                            I've read & agree to the{" "}
                            <Link
                              onClick={() =>
                                this.setState({
                                  isTermsClicked: !this.state.isTermsClicked,
                                })
                              }
                              style={{
                                color: "#00425f",
                                fontWeight: 700,
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                              to={`${url}terms`}
                            >
                              Terms & Conditons
                            </Link>
                          </label>
                        </div>
                        <br />
                        {checkBoxError && (
                          <small
                            className="error"
                            style={{
                              color: "#ee312a",
                            }}
                          >
                            Accept the terms and conditions to continue
                          </small>
                        )}
                      </div>

                      <div
                        className="col-12"
                        style={{
                          padding: 0,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: `${20}px`,
                          }}
                        >
                          <div className="col-4">
                            <Button
                              onClick={this.goBack}
                              disabled={loading ? true : false}
                              className={
                                loading
                                  ? `isw-btn  bg-primary text-white w-100 false`
                                  : `isw-btn isw-btn--raised bg-primary text-white w-100`
                              }
                            >
                              <span>Previous</span>
                            </Button>
                          </div>
                          <div className="col-4">
                            {this.renderButton(
                              "Sign Up",
                              loading,
                              this.onSubmit
                            )}
                          </div>
                        </div>

                        <div className="mt-4 text-center">
                          <p>
                            Do you have an account already?{" "}
                            {loading ? (
                              <span className="text-primary font-weight-bold">
                                Sign in
                              </span>
                            ) : (
                              <Link
                                to={`${url}signin`}
                                className="text-primary font-weight-bold"
                              >
                                Sign in
                              </Link>
                            )}
                          </p>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
                {/* <div className="col-12"> */}
                {/* {this.renderButton("Continue", null, this.Continue)} */}

                <div className="footer-wrapper">
                  <p className="footer-text">
                    Powered by <FooterLogoIcon classname={"pb-1 pl-1"} />
                  </p>
                </div>
                {/* </div> */}
              </div>
            </div>

            <Static />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ signup_reducer }) => {
  const { signup, data, email, phone } = signup_reducer;
  return {
    signup,
    data,
    email,
    phone,
  };
};

export default connect(mapStateToProps)(Signup);
